<template>
    <div class="modal fade" :class="{ show: show }" id="mysterybox" tabindex="-1" aria-labelledby="#mysterybox" 
      :aria-hidden="!show" :aria-modal="show" :style="{ display: show ? 'block' : 'none' }">
      <div ref="nftBoxModalContainer" class="modal-dialog">
        <div class="modal-content font-main modal-border box-shadow" style="width:100%;background-size:cover;background-repeat:no-repeat;background-image:url('Bootswatch_ Cyborg_files/images/mainbg.svg')">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Mystery NFT-Boxes</h1>
            <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <!-- 
            <a href="https://twitter.com/tezos_hit_coin" target="_blank">
              <img src="Bootswatch_ Cyborg_files/images/SHIT-sponsor-sm1.gif" style="width:90%;background-size:contain ;background-repeat:no-repeat;padding-left:75px;">
            </a>

            <h6 class="text-center text-warning small mt-2"><i class="bi bi-exclamation-circle me-1">Sponsored by <a class="text-warning" href="https://tezoshitcoin.xyz/" target="_blank">💩Tezos $HITcoin 
</a></i>    </h6>

-->

          <div class="modal-body">

            <div class="text-center">
                <ul class="nav nav-tabs font-main-italic" role="tablist" style="display:inline-flex;">

                  <li class="nav-item" role="presentation">
                    <button @click="switchTab2('pfp')" :class="{ active: tab2 === 'pfp' }" class="nav-link"
                      aria-selected="true" role="tab"><i class="bi bi-1-circle-fill me-1"></i> PFP Box</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab2('art')" :class="{ active: tab2 === 'art' }" class="nav-link"
                      aria-selected="false" tabindex="-1" role="tab"><i class="bi bi-2-circle-fill me-1"></i> Value Mix Box <i class="bi bi-fire"></i></button>
                  </li>

                </ul>
              </div>
          </div>

          <div id="myTabContentModal2" class="tab-content text-center"
                  style="font-size:0.9em;">

                  <div class="tab-pane fade show active" v-if="tab2 === 'pfp'" role="tabpanel">
                    <div class="tabtab">
                      <div class="">
                        <div class="modal-body pfp pt-1" style="width:100%;background-size:contain ;background-repeat:no-repeat;background-image:url('Bootswatch_ Cyborg_files/images/box-banner3.png')"> <!-- Bootswatch_ Cyborg_files/images/box-banner3.png -->

                        <div class="row">

                            <div class="col col-sm-12 text-center" >

                                <!-- <h6 class="text-warning small"><i class="bi bi-exclamation-circle"> Sponsored by <a class="text-warning" href="https://objkt.com/collection/KT1HoAoMUtKLyevCzu2UxMSodRtMYpu5ATBm" target="_blank">Tezakai</a></i></h6>-->

                                <h6 class="mb-0">{{ raffleStore.boxcount }} NFTS in Box <a class="color-none" href="https://legacy.objkt.com/profile/KT1AnoXF2FBQaXN6D2T3bKPgR99VFZWuQepY/owned?sort=highest_offer:desc" target="_blank"> <i class="bi bi-zoom-in ms-1"></i></a></h6>
                                <img src="Bootswatch_ Cyborg_files/images/treasure-closed2-b.png" class="tilt-shaking mb-1" style="max-width:230px;width:70%;">

                            </div>

                        </div>

                        <div class="row">

                            <div class="col col-sm-12 mt-2 mb-2 text-center">
                                
                                <button @click="openBoxTez" class="btn btn-primary tilt-shaking ms-1 box-shadow box-shadow-golden mb-2" :disabled="this.paused" style="top:0px;font-size:22px;margin-left:50px;border:1px solid #fff">
                                <span v-if="working">
                                    <self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" />
                                </span>
                                <span v-else style="color: #ffb300;"><img src="Bootswatch_ Cyborg_files/images/key-b.png" class="tilt-shaking" style="width:45px;"> 4.44 Tez</span>
                                </button>
                                <div class="mt-2 mb-2">Open to receive 1 random Box-NFT</div>
                                <h6 class="text-warning small" style="padding-left:5%;padding-right:5%;">
                                <i class="bi bi-info-circle"></i> Box gets refilled periodically<br>
                                <i class="bi bi-exclamation-triangle"></i> Read the <a class="text-warning" href="https://www.tzraffles.fun/#faqModal" target="_blank">F.A.Q.</a> first
                                </h6>
                            </div>

                            <div class="row">         
                            </div>    

                            <div class="mt-3 text-center">
                            <ul class="nav nav-tabs font-main-italic" role="tablist" style="display:inline-flex;">
                              <li class="nav-item" role="presentation">
                                <button @click="switchTab('test')" :class="{ active: tab === 'test' }" class="nav-link"
                                  aria-selected="true" role="tab">History</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button @click="switchTab('test3')" :class="{ active: tab === 'test3' }" class="nav-link"
                                  aria-selected="false" tabindex="-1" role="tab">Trade-In</button>
                              </li>
                            </ul>
                            <div id="myTabContentModal" class="tab-content text-center"
                              style="font-size:0.9em;">

                              <div class="tab-pane fade show active" v-if="tab === 'test'" role="tabpanel">
                                <div class="tabtab">
                                  <div class="pt-3">
                                    <div v-if="raffleStore.boxes.length > 0">
                                      
                                        <img v-if="raffleStore.box_last_image_url" :src="raffleStore.box_last_image_url" class="ms-1 me-1" style="width:150px;"> 
                                        <div v-else class="text-center"><img src="Bootswatch_ Cyborg_files/images/raffle-spinning2.gif" style="width:125px;"><br><br><!--<self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" style="margin: auto;"/>--></div>
                                        
                                        <div v-for="e in raffleStore.boxes.slice(0,12)" :key="e.id" class="font-main-light mb-3 mt-2">
                                          <span v-if="e.tag === 'offer_accepted'">
                                              <img src="Bootswatch_ Cyborg_files/images/key-b.png" class="ms-1 me-1" style="width:20px;">
                                              <!-- <img :src="e.payload.nat" class="ms-1 me-1 mb-1" style="width:100px;">     
                                               v-if="raffleStore.pkh === 'tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2'"-->
                                              <!--<span class="small">{{ timeDifference(e.timestamp) }}: </span>--><span class="font-main" :class="{ 'text-success': e.payload.address === raffleStore.pkh}" style="">{{ formatAddress(e.payload.address) }}</span> opening Box.. <br>
                                            </span>
                                            <span v-else-if="e.tag === 'token_out'">
                                              <img src="Bootswatch_ Cyborg_files/images/treasure2-b.png" class="ms-1 me-1" style="width:25px;">
                                              <!--<span class="small"> {{ timeDifference(e.timestamp) }}: </span>--><span class="font-main" :class="{ 'text-success': e.payload.address_0 === raffleStore.pkh}" style="">{{ formatAddress(e.payload.address_0) }} <span class="me-1">🎉</span> </span>Won:
                                              <br>
                                                  <span class="small">({{ e.payload.address_1 }}- {{ e.payload.nat }})</span>
                                              <br>
                                              <a :href="`${objktLink}` + `${e.payload.address_1}` + '/' + `${e.payload.nat}`" target="_blank"><i
                                                  class="bi bi-box-arrow-in-up-right"></i><img src="Bootswatch_ Cyborg_files/images/objkt-white.png" 
                                                  style="height:15px;padding-left:5px"></a>
                                                  <ShareNetwork network="twitter" url="https://www.tzraffles.fun/#mysterybox"
                                                    twitter-user="tzRaffles"
                                                    title="I just won a NFT in the #Tezos Mysterybox. Try your luck for 4.44 Tez."
                                                    hashtags="tzRaffles,Tezos"><span class="badge bg-primary me-1 ms-2"><i class="bi bi-twitter"></i> Share</span>
                                                  </ShareNetwork>
                                            </span>  
                                        </div>
                                    </div>
                                    <div v-else>
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane fade show active" v-if="tab === 'test3'" role="tabpanel">
                                <div class="tabtab">
                                  <div class="p-2 mt-2 text-center">
                                    Or open the box with a NFT of a whitelisted collection
                                    <form>
                                    <div class="mb-1">
                                      <label for="raffle-contract-id" class="col-form-label">Select Collection:</label>
                                      <div class="input-group">
                                        <span class="input-group-text"><i class="bi bi-card-list"></i></span>
                                        <select class="form-select sm" id="raffle-contract-id"
                                          aria-label="Default select example" v-model.trim="form.kt">
                                          <!--<option select="selected" value="KT1LeGyYq61SE8mCK3ECQtzkCRHGLNsnxvUe">Mysterybox Key</option>-->
                                          <!--<option select="selected" value="KT1QrxS5HyUTKtGmNftsraUDpfJxG6WV6kuJ">Inkspunks</option>-->
                                          <!--<option select="selected" value="KT1JqZEJ29URqCDn8CreY8xftHW8AdhL9UXU">TinyTurtles</option>-->
                                          <!--<option select="selected" value="KT1PfSzijPDq9DfuZrNjQcvdnz1WudP6D68e">Mutant Gecko</option>-->
                                          <!--<option select="selected" value="KT1N8caoocysp9XFjwJfYDRZQiFUE7aYTHc8">Mutant Gecko 3D</option>-->                                    
                                          <!--<option select="selected" value="KT1CwSgYmZewFazZsW348RAQYn1nthiGP3Qa">Cyber Gecko Gang</option>--> 
                                          <option select="selected" value="KT1CJUHK9hkf24AMJfWFS6KJZmY5jb9GYfCS">Ottez Evo</option>
                                          <option select="selected" value="KT1EzcCuHwyPWHD5VgVWDQ2gxcFpzJzRExW4">Ottez Evo-Fusion</option>
                                          <option select="selected" value="KT1SyPgtiXTaEfBuMZKviWGNHqVrBBEjvtfQ">Gogos</option>
                                          <option select="selected" value="KT19BLv8px4VMLduVnYgahqFbsJ19FJXamUG">Les Elefants Terribles</option>
                                          <option select="selected" value="KT1MsdyBSAMQwzvDH4jt2mxUKJvBSWZuPoRJ">Neonz</option>
                                          <option select="selected" value="KT1QRtkWmCKSnLDnEzJVoj2ya2Ef2mTjszwH">Flux Tribe</option>
                                          <option select="selected" value="KT1PNcZQkJXMQ2Mg92HG1kyrcu3auFX5pfd8">Ziggurats</option>
                                                                              
                                          <!-- <option select="selected" value="KT1HoAoMUtKLyevCzu2UxMSodRtMYpu5ATBm">Tezakai</option>-->
                                          <!-- <option select="selected" value="KT1L5T8egU9HontMA3UQPHV3gHeVvxFW7Gib">Celestial-X Lands</option>-->
                                          <!-- <option select="selected" value="KT1BCVdzWPeR1BnjdsCeDRwhkXfRXEDjYwMh">TezNouns</option>-->
                                        </select>
                                      </div>
                                    </div>
                                    <div class="">
                                      <label for="raffle-token-id" class="col-form-label">Enter Token-ID:</label>
                                      <div class="input-group">
                                          <span class="input-group-text"><i class="bi bi-123"></i></span>
                                          <input type="text" class="form-control sm" id="raffle-token-id" v-model="form.tokenId"
                                          placeholder="1234...">
                                      </div>
                                    </div>
                                    </form>
                                    <button @click="openBoxToken" type="button" class="btn btn-primary tilt-shaking mt-4 ms-1 box-shadow" :disabled="this.paused" style="border:1px solid #fff">
                                    <span v-if="working">
                                        <self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" />
                                    </span>
                                    <span v-else><img src="Bootswatch_ Cyborg_files/images/key.png" class="tilt-shaking" style="width:25px;"> Trade-In my NFT & Open Box</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade show active" v-if="tab2 === 'art'" role="tabpanel">
                    <div class="tabtab">
                      <div class="">
                        <div class="modal-body art pt-1" style="width:100%;background-size:contain ;background-repeat:no-repeat;background-image:url('')"> <!-- Bootswatch_ Cyborg_files/images/box-banner3.png -->

                        <div class="row">

                            <div class="col col-sm-12 text-center">

                                <!-- <h6 class="text-warning small"><i class="bi bi-exclamation-circle"> Sponsored by <a class="text-warning" href="https://objkt.com/collection/KT1HoAoMUtKLyevCzu2UxMSodRtMYpu5ATBm" target="_blank">Tezakai</a></i></h6>-->

                                <h6 class="mb-0">{{ raffleStore.boxcount_art }} NFTS in Box <a class="color-none" href="https://legacy.objkt.com/profile/KT1TsLP1EgYK8vGhU3KJmqxKwLZR7AEi5Vj7/owned?sort=lowest_ask:desc" target="_blank"> <i class="bi bi-zoom-in ms-1"></i></a></h6>

                                <img src="Bootswatch_ Cyborg_files/images/treasure-closed2.png" class="tilt-shaking mb-1" style="max-width:230px;width:70%;">

                            </div>

                        </div>

                        <div class="row">

                            <div class="col col-sm-12 mt-2 mb-2 text-center">
                                
                                <button @click="openBoxArtTez" class="btn btn-primary tilt-shaking ms-1 box-shadow mb-2" :disabled="this.paused" style="top:0px;font-size:22px;margin-left:50px;border:1px solid #fff">
                                <span v-if="working">
                                    <self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" />
                                </span>
                                <span v-else><img src="Bootswatch_ Cyborg_files/images/key.png" class="tilt-shaking" style="width:45px;"> 1.11 Tez</span>
                                </button>
                                <div class="mt-2 mb-2">Open to receive 1 random Box-NFT</div>
                                
                                <h6 class="text-warning small" style="padding-left:5%;padding-right:5%;">
                                <i class="bi bi-info-circle"></i> Box gets refilled periodically<br>
                                <i class="bi bi-exclamation-triangle"></i> Read the <a class="text-warning" href="https://www.tzraffles.fun/#faqModal" target="_blank">F.A.Q.</a> first
                                </h6>
                            </div>

                            <div class="row">         
                            </div>    

                            <div class="mt-3 text-center">
                            <ul class="nav nav-tabs font-main-italic" role="tablist" style="display:inline-flex;">
                              <li class="nav-item" role="presentation">
                                <button @click="switchTab('test')" :class="{ active: tab === 'test' }" class="nav-link"
                                  aria-selected="true" role="tab">History</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button @click="switchTab('test3')" :class="{ active: tab === 'test3' }" class="nav-link"
                                  aria-selected="false" tabindex="-1" role="tab">Open with Key</button>
                              </li>
                            </ul>
                            <div id="myTabContentModal" class="tab-content text-center"
                              style="font-size:0.9em;">

                              <div class="tab-pane fade show active" v-if="tab === 'test'" role="tabpanel">
                                <div class="tabtab">
                                  <div class="pt-3">

                                    <img v-if="raffleStore.box_art_last_image_url" :src="raffleStore.box_art_last_image_url" class="" style="width:150px;"> 
                                    <div v-else class="text-center"><self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" style="margin: auto;"/></div>

                                    <div v-if="raffleStore.boxes_art.length > 0">
                                        <div v-for="e in raffleStore.boxes_art.slice(0,12)" :key="e.id" class="font-main-light mb-3 mt-2">
                                          <span v-if="e.tag === 'offer_accepted'">
                                              <img src="Bootswatch_ Cyborg_files/images/key.png" class="ms-1 me-1" style="width:20px;">
                                              <!-- <img :src="e.payload.nat" class="ms-1 me-1 mb-1" style="width:100px;">     -->
                                              <span class="small" v-if="raffleStore.pkh === 'tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2'">{{ timeDifference(e.timestamp) }}:</span><span class="me-1 font-main" :class="{ 'text-success': e.payload.address === raffleStore.pkh}" style="">{{ formatAddress(e.payload.address) }}</span> opening Box.. <br>
                                            </span>
                                            <span v-else-if="e.tag === 'token_out'">
                                              <img :src="treasure_url" @click="boxURL(e.payload.address_1, e.payload.nat)" class="ms-1 me-1" style="width:20px;">
                                              <span class="small" v-if="raffleStore.pkh === 'tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2'">{{ timeDifference(e.timestamp) }}:</span><span class="font-main" :class="{ 'text-success': e.payload.address_0 === raffleStore.pkh}" style="">{{ formatAddress(e.payload.address_0) }} <span class="me-1">🎉</span> </span>Won:
                                              <br>
                                                  <span class="small">({{ e.payload.address_1 }}- {{ e.payload.nat }})</span>
                                              <br>
                                              <a :href="`${objktLink}` + `${e.payload.address_1}` + '/' + `${e.payload.nat}`" target="_blank"><i
                                                  class="bi bi-box-arrow-in-up-right"></i><img src="Bootswatch_ Cyborg_files/images/objkt-white.png"
                                                  style="height:15px;padding-left:5px"></a>
                                            </span>  
                                        </div>
                                    </div>
                                    <div v-else>
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                        <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane fade show active" v-if="tab === 'test3'" role="tabpanel">
                                <div class="tabtab">
                                  <div class="p-2 mt-2 text-center">
                                    Open the box with a Mysterybox Key
                                    <div v-show="false">
                                      <form>
                                      <div class="mb-1">
                                        <label for="raffle-contract-id" class="col-form-label">Select Collection:</label>
                                        <div class="input-group">
                                          <span class="input-group-text"><i class="bi bi-card-list"></i></span>
                                          <select class="form-select sm" id="raffle-contract-id"
                                            aria-label="Default select example" v-model.trim="form.kt">
                                            <option select="selected" value="KT1LeGyYq61SE8mCK3ECQtzkCRHGLNsnxvUe">Mysterybox Key</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="">
                                        <label for="raffle-token-id" class="col-form-label">Enter Token-ID:</label>
                                        <div class="input-group">
                                            <span class="input-group-text"><i class="bi bi-123"></i></span>
                                            <input type="text" class="form-control sm" id="raffle-token-id" v-model="form.tokenId" value="0"
                                            placeholder="1234...">
                                        </div>
                                      </div>
                                      </form>
                                   </div>
                                   <div>
                                    <button @click="openBoxArtToken" type="button" class="btn btn-primary tilt-shaking mt-4 ms-1 box-shadow" :disabled="this.paused" style="border:1px solid #fff">
    
                                      <span v-if="working">
                                        <self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" />
                                    </span>
                                    <span v-else><img src="Bootswatch_ Cyborg_files/images/key.png" style="width:25px;"> Open with Key</span>
                                    </button>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { SelfBuildingSquareSpinner } from 'epic-spinners'
  import { mapStores } from 'pinia'
  import { useRaffleStore } from '@/stores/RaffleStore'
  import { formatPkhString } from "../utils";

  export default {
    name: 'ModalsNFTBox',
    props: {
      show: Boolean
    },
    emits: ['close-modal', 'close'],
    components: {
      SelfBuildingSquareSpinner,
    },
    data() {
      return {
        optional: false,
        working: false,
        tab: 'test',
        tab2: 'pfp',
        objktLink: "https://objkt.com/asset/",
        imageUrl: "https://placehold.co/150x100?text=NFT-Preview",
        treasure_url: "Bootswatch_ Cyborg_files/images/treasure.png",
        form: {
          kt: 'KT1LeGyYq61SE8mCK3ECQtzkCRHGLNsnxvUe',
          tokenId: '0',
        },
        paused: false
      }
    },
    computed: {
      ...mapStores(useRaffleStore),
    }, 
    methods: {
        switchTab(tab) {
            this.tab = tab
            },
          switchTab2(tab) {
            this.tab2 = tab
            },        
       async boxURL(kt, tokenId){
                try { 
                   console.log("lol");
                    const meta = await this.raffleStore.getTokenMetaData({ kt: kt, tokenId: tokenId });
                    console.log(meta);
                    let shortlink = ''
                    if (typeof meta[0]?.metadata?.displayUri !== "undefined") { shortlink = meta[0]?.metadata?.displayUri.substring(7) } else { shortlink = "" }
                    let url = "https://nftstorage.link/ipfs/" + shortlink
                    console.log(url);
                    this.treasure_url = url;
                    return url
                } catch(errorReason) { 
                // code on error
                }
        },
      async openBoxTez() {
        try {
          this.working = true
          this.raffleStore.openBoxTez({ amount: 4440000})
          await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
        } catch (e) {
          console.log(e)
        } finally {
          this.working = false
        }
      },
      async openBoxArtTez() {
        try {
          this.working = true
          this.raffleStore.openBoxArtTez({ amount: 1110000})
          await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
        } catch (e) {
          console.log(e)
        } finally {
          this.working = false
        }
      },
      async openBoxToken() {
        try {
          this.working = true
          this.raffleStore.openBoxToken({amount: 0, kt: this.form.kt, tokenId: this.form.tokenId})
          await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
        } catch (e) {
          console.log(e)
        } finally {
          this.working = false
        }
      },
      async openBoxArtToken() {
        try {
          this.working = true
          this.raffleStore.openBoxArtToken({amount: 0, kt: this.form.kt, tokenId: this.form.tokenId})
          await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
        } catch (e) {
          console.log(e)
        } finally {
          this.working = false
        }
      },
      formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    },
      // prevent clicking inside the card from triggering the closeModal
      stopPropagation(e) {
        e.stopPropagation()
      },
      closeModal() {
        this.$emit('close-modal')
      },
      timeDifference(previous) {

        var current = new Date();
        previous = new Date(previous)

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + 's ago';
        }

        else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + 'm ago';
        }

        else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + 'h ago';
        }

        else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + 'D ago';
        }

        else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + 'M ago';
        }

        else {
        return Math.round(elapsed / msPerYear) + 'Y ago';
        }
        }
    },
    setup() {

  },
    async mounted() {
        const store = useRaffleStore()
        store.setBoxes()
        console.log(store.boxes)
        store.setBoxArt()
        console.log(store.boxes_art)

        setInterval(async function () {
        // console.log('refreshing events', Date.now())
        store.setBoxArt()
        }, 45000);

        setInterval(async function () {
        // console.log('refreshing events', Date.now())
        store.setBoxes()
        }, 45000);

    },
    
    beforeUnmount() {

    },
  }
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.box-shadow-golden { 
  background-color: #53250f;
}
.box-shadow-golden:before {
    background: linear-gradient(-45deg, #eeff00 0%, #ff3c00 100% ) !important;
}
</style>