<template >
  <div class="featured-raffles-element" :class="raffleStore.theme === 'pepe' ? 'pepebg' : ''">

    <div class="container pt-2 pb-5">
      <h5 v-if="raffleStore.user_filter" class="font-main-light-italic pt-1 mt-2">Raffle-Shop of {{formatAddress(raffleStore.user_filter)}}</h5>
      <h5 v-else class="font-main-light-italic pt-1 mt-2">Featured Raffles</h5>
      <div class="row">
        <Flicking class="flicking-container" :options="{
          align: 'center', circular: true, circularFallback: 'bound'
        }" :plugins="plugins" v-if="dataReady">
        <!--
          <div class="card card-hover box-shadow main-card mt-3 mb-3"
            style="text-decoration: none!Important;width:300px;">
            <a href="#tzraffle">
              <div class="img-wrapper" style="height:415px">
                <img src="Bootswatch_ Cyborg_files/images/tzRaffle7.gif" class="d-block w-100"
                  style="min-height:415px !important">
              </div>
              <ul class="list-group list-group-flush text-center font-main">

                <li v-if="(1690311600000 - new Date().getTime() <= 0)" class="list-group-item item-sold"
                  style="">
                  <i class="bi bi-ticket-detailed-fill pe-1"></i>
                  {{ 1700 - this.balance  }}/1700 SOLD
                </li>

                <li class="list-group-item item-enter">
                  <div class="font-main-light small mb-1">
                     <vue-countdown v-if="1701633600000 > new Date().getTime()"
                      :time="1701633600000 - new Date().getTime()" :interval="100"
                      v-slot="{ days, hours, minutes, seconds }" style="text-decoration: none!Important;">
                      Sale Starts in
                      <i class="bi bi-clock-history pe-1"></i>
                      <span v-show="days > 0">{{ days }}D:</span>
                      <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H:{{ minutes }}M:{{ seconds
                      }}S</span>
                    </vue-countdown>
                    <vue-countdown v-else :time="1704312000000 - new Date().getTime()" :interval="100"
                      v-slot="{ days, hours, minutes, seconds }" style="text-decoration: none!Important;">
                      SALE LIVE
                      <i class="bi bi-clock-history pe-1"></i>
                      <span v-show="days > 0">{{ days }}D:</span>
                      <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H:{{ minutes }}M:{{ seconds
                      }}S</span>
                    </vue-countdown>
                  </div>
                  <a href="#tzraffle" class="card-link">
                    <button type="button" class="btn btn-primary w-100" style="height: 40px;"><i
                        class="bi bi-ticket-detailed-fill me-1"></i>
                    <span v-if="raffleStore.theme === 'pepe'">2000 $pepe</span><span v-else>1 Tez</span>
                    </button>
                  </a>
                </li>
              </ul>
            </a>
          </div>
          -->
          <div class="card card-hover box-shadow main-card mt-3 mb-3"
            style="text-decoration: none!Important;">
            <a href="#mysterybox">
              <div class="card-body text-center text-truncate pb-075">
                <h5 class="card-title text-truncate font-main-light fs-1-2 mb-1 color-light-purple">
                   Mysterybox
                </h5>
                <h6 class="card-title text-truncate font-main">
                  PFP Box
                </h6>
                <span class="badge bg-light badge-max-width text-truncate me-1"><i class="bi bi-card-image"></i> Various </span>
                <span class="badge bg-secondary badge-max-width text-truncate"><i class="bi bi-person-fill"></i> tzraffles.tez</span> 
              </div>
              <div class="img-wrapper">
                <img src="Bootswatch_ Cyborg_files/images/treasure-closed2-b.png" class="d-block w-100 p-2">
              </div>
              <ul class="list-group list-group-flush text-center font-main">

                <li class="list-group-item item-sold"
                  style="">
                  <i class="bi bi-images pe-1"></i>
                  100+ NFTs
                </li>

                <li class="list-group-item item-enter">
                  <div class="font-main-light small mb-1">
                    <i class="bi bi-clock-history pe-1"></i> LIVE
                  </div>
                    <button type="button" class="btn btn-primary w-100"><i
                        class="bi bi-key me-1"></i>
                        Open
                    </button>
                </li>
              </ul>
            </a>
          </div>
          <div class="card card-hover box-shadow main-card mt-3 mb-3"
            style="text-decoration: none!Important;">
            <a href="#mysterybox">
              <div class="card-body text-center text-truncate pb-075">
                <h5 class="card-title text-truncate font-main-light fs-1-2 mb-1 color-light-purple">
                   Mysterybox
                </h5>
                <h6 class="card-title text-truncate font-main">
                  Value Mix Box
                </h6>
                <span class="badge bg-light badge-max-width text-truncate me-1"><i class="bi bi-card-image"></i> Various </span>
                <span class="badge bg-secondary badge-max-width text-truncate"><i class="bi bi-person-fill"></i> tzraffles.tez</span> 
              </div>
              <div class="img-wrapper">
                <img src="Bootswatch_ Cyborg_files/images/treasure-closed2.png" class="d-block w-100 p-2">
              </div>
              <ul class="list-group list-group-flush text-center font-main">

                <li class="list-group-item item-sold"
                  style="">
                  <i class="bi bi-images pe-1"></i>
                  50+ NFTs
                </li>

                <li class="list-group-item item-enter">
                  <div class="font-main-light small mb-1">
                    <i class="bi bi-clock-history pe-1"></i> LIVE
                  </div>
                    <button type="button" class="btn btn-primary w-100"><i
                        class="bi bi-key me-1"></i>
                    Open
                    </button>
                </li>
              </ul>
            </a>
          </div>
          <RaffleCard v-for="raffle in raffleStore.rafflesFeatured" :raffle="raffle" :key="Number(raffle.id)"
            :fav="myFav.includes(parseInt(raffle.id))" class="card card-hover box-shadow main-card mt-3 mb-3" />
          <template #viewport>
            <span class="flicking-arrow-prev is-circle d-none d-lg-block"></span>
            <span class="flicking-arrow-next is-circle d-none d-lg-block"></span>
          </template>
        </Flicking>
        <Flicking :options="{ align: 'prev' }" v-else>
          <RaffleCardSkeleton v-for="index in 7" :key="index" class="card card-hover box-shadow main-card mt-3 mb-3" />
        </Flicking>
      </div>
    </div>
  </div>
</template>

<script>
import { toRaw } from "vue";
import Flicking from "@egjs/vue3-flicking"
import RaffleCard from "./RaffleCard"
import RaffleCardSkeleton from "./RaffleCardSkeleton"
import { Arrow } from "@egjs/flicking-plugins";
import { AutoPlay } from "@egjs/flicking-plugins";
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'
import { formatPkhString } from "../utils";
// import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'FeaturedRafflesElement',
  props: {
    msg: String,
  },
  components: {
    Flicking,
    // VueCountdown: VueCountdown,
    RaffleCard,
    RaffleCardSkeleton,
  },
  data() {
    return {
      plugins: [new Arrow(), new AutoPlay({ duration: 5000, direction: "NEXT", stopOnHover: true })],
      balance: 0,
      dataReady: false,
    }
  },
  async mounted() {
    // tzRaffle #7 tokenId 8, 2000 supply
    this.balance = await this.raffleStore.getRaffleBalance(8) || 2000
    this.dataReady = true;
  },
  computed: {
    ...mapStores(useRaffleStore),
    myFav() {
      // console.log(this.raffleStore.myFavorites)
      return toRaw(this.raffleStore.myFavorites) || []
    }
  },
  methods: {
    getTime(date) {
      const datetime = new Date(date)
      if (datetime.getTime() - new Date().getTime() < 0) return 0
      return datetime !== 'Invalid Date' ? datetime.getTime() - new Date().getTime() : null
    },
    // return tez.domain or shorten wallets
    formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    },
    // preven
  },
}
</script>
 