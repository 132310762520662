<template>
  <h3>Platform Stats <br><small>Provided by Opeculiar</small></h3>
  <h5>{{ stats.raffles_total + 200 }} Raffles</h5>
  <div class="font-main-light small mb-1"><i class="bi bi-star-fill" /> {{ stats.raffles_featured }} Featured Raffles
  </div>
  <div class="font-main-light small mb-1"><i class="bi bi-piggy-bank-fill" /> {{ stats.raffles_bonus_count }} with Bonus
    Prize
  </div>
  <div class="font-main-light small mb-1"><i class="bi bi-ticket-detailed-fill" /> {{ stats.tickets_sold }} Tickets Sold
  </div>
  <div class="font-main-light small mb-1"><i class="bi bi-gift-fill" /> {{ stats.prizes_claimed }} Claimed Prizes</div>
  <br />
  <h5><i class="bi bi-person-fill" /> {{ stats.rafflers_count }} Users</h5>
  <div class="font-main-light small mb-1"><i class="bi bi-trophy-fill" /> {{ stats.rafflers_winners }} Winners</div>
</template>
  
<script>
// import VueCountdown from '@chenfengyuan/vue-countdown'
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';
import { formatPkhString } from "../../utils";

export default {
  name: 'PlatformStats',
  props: {
    stats: {}
  },
  components: {
    // VueCountdown: VueCountdown,
    // SelfBuildingSquareSpinner,
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
  methods: {
    formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    }
  },
  data() {
    return {
    }
  },
}
</script>
  
<style scoped></style>